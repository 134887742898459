import { Box } from "@chakra-ui/react";
import React, { useState, createRef, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { gsap } from "gsap";
import Finger from "../assets/svg/finger.inline.svg";
import MouthClosed from "../assets/svg/mouthclosed.inline.svg";
import MouthOpen from "../assets/svg/mouthopen.inline.svg";
import SEO from "../components/seo";
import Nav from "../components/Nav";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 680px;
	margin: 120px auto 20px;
	padding-left: 16px;
	padding-right: 16px;
`;

const FingerWrapper = styled.div`
	position: absolute;
	top: 0;
`;
const MouthWrapper = styled.div`
	position: relative;
	top: 0;
	display: flex;
	justify-content: center;
`;
const ParaGraph = styled.div`
	margin-top: 40px;
	text-align: center;
	p {
		font-family: "Poppins";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
	}
`;

export default function HiljainenPage({ data: { page } }) {
	const [playing, setPlaying] = useState(false);

	const music = createRef({ current: null });
	const finger = createRef();

	const musicSrc = "https://mec.fi-h.seravo.com/wp-content/uploads/2020/10/AmbientChoir.mp3";

	const toggle = () => {
		if(!playing) {
			setPlaying(true)
			music.current.play()
		} else {
			setPlaying(false)
			music.current.pause()
		}
	}

	useEffect(() => {
		gsap.set(finger.current, { x: 80, y: 11 });
	}, []);

	useEffect(() => {
		playing
			? gsap.to(finger.current, { y: 64, duration: 0.2 })
			: gsap.to(finger.current, { y: 11, duration: 0.2 });
	}, [playing, finger]);

	return (
		<>
		<SEO title={page.seo.title} description={page.seo.metaDesc} />
		<Nav />
		<Container>
			<Box
				pos="relative"
				onClick={toggle}
				w="200px"
				h="180px"
				cursor="pointer"
			>
				<MouthWrapper>
					{playing ? (
						<Box ml="5px">
							<MouthOpen />
						</Box>
					) : (
						<Box>
							<MouthClosed />
						</Box>
					)}
				</MouthWrapper>
				<FingerWrapper ref={finger}>
					<Finger />
				</FingerWrapper>
			</Box>

			<ParaGraph dangerouslySetInnerHTML={{ __html: page.content }} />
			<audio ref={music} src={musicSrc} />
		</Container>
		</>
	);
}

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "internetin-hiljainen-huone" }) {
			content
			title
			seo {
				metaDesc
				title
			}
		}
	}
`;
